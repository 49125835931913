<template>
    <b-modal :id="id" hide-footer centered>
        <header>
            <slot name="header"/>
        </header>
        <main>
            <slot/>
        </main>
        <footer>
            <slot name="footer"/>
        </footer>
    </b-modal>
</template>

<script>
    export default {
        name: "UModal",
        props: {
            id: {
                type: String,
                default: 'my-modal',
            }
        },
    }
</script>

<style scoped>

</style>